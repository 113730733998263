.DDReport {
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;

  .cardName {
    text-align: center;
    //    color: white;
  }

  .nav {
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .nav-item {
    font-weight: 500;
    font-size: 14px;


  }
}
.worldstats {
  min-height: 450px;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .background {
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 50vw;
      height: auto;
      object-fit: cover;
    }
  }

  .content {
    width: 100vw;
    margin-top: -550px;
    height: 400px;
  }
  p {
    padding: 2em;
  }
  * {
    text-align: center;
  }

  .flipnumber {
    font-size: 100px;
    font-family: "Lexend";
    color: black;
    font-weight: 400;
  }
}

$columns: 5;
$slide: 20%;
$height: 850px;
$minWidth: 200px;
$gap: 9%;
$horizontalGap: 5%;
$breakpoint: 1200px;
.funnel {
  border-bottom: rgba(0, 0, 0, 0.1) 2px solid;
  .tagLine {
    width: 50%;
    padding-top: 5em;
    padding-bottom: 1em;
  }

  .mobile .tag {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  @media (min-width: $breakpoint) {
    .tagLine {
      width: 45%;
    }
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }

  @media (max-width: $breakpoint) {
    .tagLine {
      width: 75%;
      margin: auto;
    }

    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
}

.trapezoid-tag-container {
  display: flex;
  //justify-content: center;
  //align-items: center;
  flex-direction: row;

  div {
    min-width: $minWidth;
    transition: 0.4s;
    padding-left: 10px;
    padding-right: 10px;
    width: $slide;
  }

  .divHover {
    transform: translateY(-5px);
    //transform: scale3d(1.2, 1.2, 1.2);
  }

  h3 {
    text-transform: uppercase;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }

  span {
    font-size: smaller;
  }
}

.trapezoid-title-container {
  height: $height;
  display: flex;
  //justify-content: center;
  //align-items: center;
  flex-direction: row;

  div {
    min-width: $minWidth;
    transition: 0.4s;
    padding-left: 10px;
    padding-right: 10px;
    border-left: rgba(0, 0, 0, 0.1) 2px solid;
    width: $slide;
  }

  .divHover {
    transform: translateY(-5px);
    //transform: scale3d(1.2, 1.2, 1.2);
  }

  h3 {
    text-transform: uppercase;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }

  span {
    font-size: smaller;
  }
}

.trapezoid-container {
  z-index: 10;
  margin-top: calc(($height - 50px) * -1);
  height: calc($height - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.trapezoid-step * {
  text-align: center;
  margin: 0;
  padding: 0;
  opacity: unset;
}

.trapezoid-step {
  width: $slide;
  height: $height * 0.75;
  color: white;
  flex-direction: row;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  min-width: $minWidth;
  transition: 0.4s;
}

// .trapezoid-step:hover {
//   opacity: 1;
//   transform: translateY(-5px);
//   //transform: scale3d(1.2, 1.2, 1.2);
// }

.trapezoid-step h2 {
  font-size: 16px;
  opacity: unset;
}

$beginning: 100% - $gap;

@for $i from 0 through $columns {
  .trapezoid-step-#{$i} {
    cursor: pointer;
    left: calc(max($minWidth, $slide * $i));
    background-color: #3e7cb1;
    opacity: 0.35+ 0.15 * $i;
    padding: 10px;
    border-left: rgba(0, 0, 0, 0.1) 2px solid;
    clip-path: polygon(
      0 calc($gap * $i),
      100% calc($gap + $gap * $i),
      100% calc($beginning - ($gap * $i)),
      0% calc(100% - ($gap * $i))
    );
  }
}

.trapezoid-vertical-container {
  color: white;
  text-align: center;
  height: $height/2;
  transition: 0.4s;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    text-transform: uppercase;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
  }

  span {
    font-size: 12px;
  }
  div {
    width: 40%;
  }

  .tag {
    position: absolute;
    color: black;
  }
}

.trapezoid-vertical-container:hover {
  opacity: 1 !important;
}
@for $i from 0 through $columns {
  .trapezoid-vertical-container:nth-child(#{$i}) {
    transition: 0.4s;

    background-color: #3e7cb1;
    opacity: 0.25+ 0.15 * $i;
    //padding: 10px;

    clip-path: polygon(
      calc($horizontalGap * $i) 0,
      calc(100% - $horizontalGap * $i) 0%,
      calc(100% - $horizontalGap * ($i + 1)) 100%,
      calc($horizontalGap * ($i + 1)) 100%
    );
  }
}

.trapezoid-vertical-container:nth-last-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  div {
    width: 25%;
  }
}

.speech-bubble {
  text-align: left;
  position: relative;
  background-color: rgba(0, 0, 0, 0.1);
  color: #333333;
  border-radius: 3px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.speech-bubble:after {
  content: "";
  position: absolute;
  top: 0;
  left: 80%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-top: 0;
  margin-left: -5px;
  margin-top: -10px;
}

.mobile .speech-bubble:after {
  left: 50% !important;
}

.mobile .speech-bubble {
  min-height: inherit !important;
}

.TeamMembers {
  font-family: "Lexend";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  justify-content: "center";
  padding: 0px;

  .header {
    border: none !important;

    background: rgba(0, 0, 0, 0.05);
  }
}

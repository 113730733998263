@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@200;300;400;500&display=swap");
@import "custom";

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;

  .text-info {
    color: $brand-color !important;
  }

}

.result-block-container .result-block {
  opacity: 1;
}